import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";

function parseDateTime(text) {
  if (text === null) {
    return null;
  }
  const date = parseISO(text);
  if (isValid(date)) {
    return date;
  }
  return null;
}

export { parseDateTime };
